export default {
  id: 'd29ed6efa1',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n				<div class="left">\n					<a href="#" class="link back">\n						<i class="icon icon-back"></i>\n						<span class="if-not-md" style="transform: translate3d(0px, 0px, 0px);">Voltar</span>\n					</a>\n					<a href="#" class="link icon-only panel-open" data-panel="left" style="display:none;">\n						<i class="icon f7-icons if-not-md">menu</i>\n						<i class="icon material-icons if-md">menu</i>\n					</a>\n				</div>\n				<div class="title sliding">';
      r += c(ctx_1.restaurantName, ctx_1);
      r += '<span class="subtitle">';
      r += c(ctx_1.categoryName, ctx_1);
      r += '</span></div>\n				<div class="right" style="display:none;">\n					Idiomas\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			';
      r += c(ctx_1.products, ctx_1);
      r += '\n		</div>\n		<div class="toolbar toolbar-bottom tabbar tabbar-labels">\n			<div class="toolbar-inner">\n				<a href="/" class="tab-link panel-close">\n					<i class="fas fa-home"></i>\n					<span class="tabbar-label">Inicio</span>\n				</a>\n				<a href="/ementa/';
      r += c(ctx_1.userID, ctx_1);
      r += '/menu/0/" class="tab-link panel-close tab-link-active">\n					<i class="fas fa-book-open"></i>\n					<span class="tabbar-label">Menu</span>\n				</a>\n				<a href="/ementa/';
      r += c(ctx_1.userID, ctx_1);
      r += '/wines/0/" class="tab-link panel-close">\n					<i class="fas fa-wine-bottle"></i>\n					<span class="tabbar-label">Vinhos</span>\n				</a>\n				<a href="/ementa/';
      r += c(ctx_1.userID, ctx_1);
      r += '/sugestions/0/" class="tab-link panel-close">\n					<i class="fas fa-comments"></i>\n					<span class="tabbar-label">Sugestões</span>\n				</a>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};