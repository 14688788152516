
import HomePage from '../pages/home.f7.html';
import AboutPage from '../pages/about.f7.html';
import FormPage from '../pages/form.f7.html';

import EmentaPage from '../pages/ementa.f7.html';
import ProductPage from '../pages/product.f7.html';
import NotFoundPage from '../pages/404.f7.html';

var routes = [
	{
		path: '/',

		async: function (routeTo, routeFrom, resolve, reject) {
			var router = this;
			var app = router.app;
			app.dialog.preloader('A carregar...')
			var userId = routeTo.params.userId;

			var menus = '';
			app.request.promise.get('https://apps.brvr.pt/teste.php', { 'userId': userId, type: 'home' }, 'json').then(function (res) {
				menus = res.data.html;
				setTimeout(function(){ app.dialog.close(); }, 400);
				resolve(
				{
					component: HomePage,
				},
				{
					context: {
						user: menus,
						userID: '01'
					}
				})
			})
		},	
	},
	{
		path: '/about/',
		component: AboutPage,
	},
	{
		path: '/form/',
		component: FormPage,
	},
	{
		path: '/ementa/:userId/:typeId/:menuId/',
		async: function (routeTo, routeFrom, resolve, reject) {
			var router = this;
			var app = router.app;
			app.dialog.preloader('A carregar...')

			var userId = routeTo.params.userId;
			var typeId = routeTo.params.typeId;
			var menuId = routeTo.params.menuId;

			var products = '';
//						app.request.promise.get('http://607619c0.ngrok.io/api/category/6b039f18ecbeb', { 'userId': userId, type: typeId , menuId: menuId }).then(function (res) 

			app.request.promise.get('https://apps.brvr.pt/teste.php', { 'userId': userId, type: typeId , menuId: menuId }, 'json').then(function (res) 
			{
				products = res.data.html;
				setTimeout(function(){ app.dialog.close(); }, 400);
				resolve(
				{
					component: EmentaPage,
				},
				{
					context: {
						products: products,
						userID: userId,
						restaurantName: res.data.name,
						categoryName: res.data.categoryName
					}
				})
			})
		},
	},
	{
		path: '/product/:userId/:productId/',
		async: function (routeTo, routeFrom, resolve, reject) {
			var router = this;
			var app = router.app;
			app.dialog.preloader('A carregar...')

			var userId = routeTo.params.userId;
			var productId = routeTo.params.productId;

			var products = '';
//						app.request.promise.get('http://607619c0.ngrok.io/api/category/6b039f18ecbeb', { 'userId': userId, type: typeId , menuId: menuId }).then(function (res) 

			app.request.promise.get('https://apps.brvr.pt/teste.php', { userId: userId, type: 'product', productId: productId }, 'json').then(function (res) 
			{
				products = res.data.html;
				setTimeout(function(){ app.dialog.close(); }, 400);
				resolve(
				{
					component: ProductPage,
				},
				{
					context: {
						products: products,
						userID: userId,
						restaurantName: res.data.name,
						categoryName: res.data.categoryName
					}
				})
			})
		},
	},
	{
		path: '(.*)',
		component: NotFoundPage,
	},
];

export default routes;