export default {
  id: '5e0b025131',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="home">\n		<!-- Top Navbar -->\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n				<div class="left">\n					<a href="/" class="link icon-only panel-close">\n						<i class="material-icons">home</i>\n						<span class="tabbar-label"></span>\n					</a>\n					<a href="#" class="link icon-only panel-open" data-panel="left" style="display:none;">\n						<i class="icon f7-icons if-not-md">menu</i>\n						<i class="icon material-icons if-md">menu</i>\n					</a>\n				</div>\n				<div class="title sliding">O meu menu digital</div>\n				<div class="right" style="display:none;">\n					Idiomas\n				</div>\n			</div>\n		</div>		\n		<!-- Scrollable page content-->\n		<div class="page-content">\n			<div style="background:url(\'static/images/photo2.jpg\');background-size: cover;background-position: center;width:100%;height:100%;">\n				<div class="logotipo"><img src="static/images/logo.svg"></div>\n			</div>\n		</div>\n		<div class="toolbar toolbar-bottom tabbar tabbar-labels">\n			<div class="toolbar-inner">\n				<a href="/" class="tab-link panel-close">\n					<i class="fas fa-home"></i>\n					<span class="tabbar-label">Inicio</span>\n				</a>\n				<a href="/ementa/';
      r += c(ctx_1.userID, ctx_1);
      r += '/menu/0/" class="tab-link panel-close">\n					<i class="fas fa-book-open"></i>\n					<span class="tabbar-label">Menu</span>\n				</a>\n				<a href="/ementa/';
      r += c(ctx_1.userID, ctx_1);
      r += '/wines/0/" class="tab-link panel-close">\n					<i class="fas fa-wine-bottle"></i>\n					<span class="tabbar-label">Vinhos</span>\n				</a>\n				<a href="/ementa/';
      r += c(ctx_1.userID, ctx_1);
      r += '/sugestions/0/" class="tab-link panel-close">\n					<i class="fas fa-comments"></i>\n					<span class="tabbar-label">Sugestões</span>\n				</a>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};