export default {
  id: '441226d70b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n<div class="page">\n  <div class="navbar">\n    <div class="navbar-bg"></div>\n    <div class="navbar-inner sliding">\n      <div class="left">\n        <a href="#" class="link back">\n          <i class="icon icon-back"></i>\n          <span class="if-not-md">Back</span>\n        </a>\n      </div>\n      <div class="title">Not found</div>\n    </div>\n  </div>\n  <div class="page-content">\n    <div class="block block-strong">\n      <p>Sorry</p>\n      <p>Requested content not found.</p>\n    </div>\n  </div>\n</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};